<template>
  <home-comp msg="Welcome to Your home"/>
</template>

<script>
  import HomeComp from '../components/Home'

  export default {
    name: 'HomeView',

    components: {
      HomeComp,
    },
  }
</script>
