<template>
  <v-form 
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-container dense=true>
      <v-row>
        <v-col> Adreel Profile</v-col>
      </v-row>
      <v-row>
      <v-col cols="12" md="6">  
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="username"
            label="User Name"
            :readonly="editFlag"
            :disabled="editFlag"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="email"
            :rules="rules.email"
            label="Email"
            :readonly="editFlag"
            :disabled="editFlag"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="birthday"
            label="Birth Day"
            :readonly="editFlag"
            :disabled="editFlag"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="gender"
            label="Gender"
            :readonly="editFlag"
            :disabled="editFlag"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="zip"
            label="Zip Code"
            required
            :readonly="editFlag"
            :disabled="editFlag"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="phone"
            label="Phone"
            :readonly="editFlag"
            :disabled="editFlag"
          >
          </v-text-field>
        </v-col>
      </v-row>
      </v-col>
      <v-col cols="12" md="6">
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Password"
            hint="At least 8 characters"
            counter
            @click:append="show1 = !show1"
            :readonly="passFlag"
            :disabled="passFlag"
          >
          </v-text-field>
        </v-col>
      </v-row>     
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Enter Password"
            hint="At least 8 characters"
            counter
            @click:append="show1 = !show1"
            :readonly="passFlag"
            :disabled="passFlag"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Re-Enter Password"
            hint="At least 8 characters"
            counter
            @click:append="show1 = !show1"
            :readonly="passFlag"
            :disabled="passFlag"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col> 
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="validate"
          >
            Submit
          </v-btn>

          <v-btn
            color="error"
            class="mr-2"
            @click="reset"
          >
            Reset
          </v-btn>
        </v-col>
      </v-row>
      </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
  export default {
    name: 'TransferComp',
    
    data: () => ({
      editFlag: true,
      passFlag: true,
      valid: true,
      show1: false,
      password: 'Password',
      email: 'x@att.com',
      username: 'yl12',
      birthday: '01-01-2000',
      gender: 'M',
      phone: '555-555-5555',
      zip: '67892',
      rules: {
        required: value => !!value || 'Required.',
        min: v => v && v.length >= 8 || 'Min 8 characters',
        emailMatch: () => (`The email and password you entered don't match`),
        email: [
          v => !!v || 'E-mail is required',
          v => /.+@.+/.test(v) || 'E-mail must be valid',
        ],
      },
    }),
    methods: {
      validate () {
        this.$refs.form.validate()
      },
      reset () {
        this.$refs.form.reset()
        this.$refs.form.resetValidation()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
    },
  }
</script>
