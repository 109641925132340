<template>
  <earn-comp msg="Welcome to Your earning"/>
</template>

<script>
  import EarnComp from '../components/Earn'

  export default {
    name: 'EarnView',

    components: {
      EarnComp,
    },
  }
</script>
