import axios from "axios"
import {baseEp} from "../utils/appUrl"


// export const login = {
  // isAuthenticated: false,
  // authenticate(cb) {
  //   this.isAuthenticated = true
  //   setTimeout(cb, 100) // fake async
  // },
  // signout(cb) {
  //   this.isAuthenticated = false
  //   setTimeout(cb, 100) // fake async
  // }
// }

export const login = async (loginModel) => {
  try{
    return await axios.post( baseEp + "/lg", {
                        email: loginModel.email,
                        pass: loginModel.pass
                      });
  }catch(err){
    console.debug("login err=", err);
    return err;
  }
}

// export const resetPass = async (p1, p2, jwtv) => {
//   try{
//     const config = {
//       headers:{
//         Authorization: jwtv
//         // Tk: tkv
//       }
//     };
//     let resp = await axios.post( baseEp + "/rp", {
//                         pass1: p1,
//                         pass2: p2
//                       }, config);
//     return resp;
//   }catch(err){
//     return err.response;
//   }
// }

// axios.get(url, config)
//   .then(res=> console.log(res))
//   .catch(err=> console.log(err))
// axios.post(url, data, config)
//   .then(res => console.log(res))
//   .catch(err => console.log(err))

// export const logout = async () => {
//   try{
//     const config = {
//       headers:{
//         jwt: sessionStorage.getItem("jwt"),
//         tk: sessionStorage.getItem("tk")
//       }
//     };
    
//     await axios.post( baseEp + "/lgo", {}, config);
//     //redirect to login
//   }catch(err){
//     console.debug("login err=", err);
//     return null;
//   }
// }

// export const retrievePass = () => {
//   axios.post( baseEp + "/retrievepass", {
//     email: 'Fred@adreel.com'
//   })
//   .then(function (response) {
//     console.debug(response);
//   })
//   .catch(function (error) {
//     console.debug(error);
//   });
// }