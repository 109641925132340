import Vue from 'vue'
import Vuex from 'vuex'
import {getTranslist, getVideoslist, execRateVideo, execCompleteSurvey, execLearnMore, execInitImp, retriveWtc, retriveSurvey, logout, resetPassword, retrieveDailyMin} from '../service/adwsvc';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    allTranslist: [],
    selectedTrans: {},
    allVideoslist: [],
    allVideoslistOrig: [],
    jwt: null,
    tk: null,
    showAppbar: false,
    currentLogo: null,
    currentProductName: null,
    currentCreditVal: null,
    currentWalletVal: null,
    currentCxid: null,
    currentProductUrl: null,
    currentProductTxt: null,
    enableMenu: true,
    currentVideoIdx: 0
  },
  plugins: [createPersistedState()],
  getters: {
    jwt: state => state.jwt,
    tk: state => state.tk,
    showAppbar: state => state.showAppbar,
    allTranslist: state => state.allTranslist,
    selectedTrans: state => state.selectedTrans,
    allVideoslist: state => state.allVideoslist,
    currentLogo: state => state.currentLogo,
    currentProductName: state => state.currentProductName,
    currentCreditVal: state => state.currentCreditVal,
    currentWalletVal: state => state.currentWalletVal,
    currentCxid: state => state.currentCxid,
    currentProductUrl: state => state.currentProductUrl,
    currentproductTxt: state => state.currentproductTxt,
    enableMenu: state => state.enableMenu,
    currentVideoIdx: state => state.currentVideoIdx
  },
  mutations: {
    setJwt: (state, jwt)  =>  (state.jwt = jwt),
    setTk: (state, tk)  =>  (state.tk = tk),
    setCurrentVideoIdx: (state, vidx)  =>  (state.currentVideoIdx = vidx),
    setShowAppbar: (state, ab)  =>  (state.showAppbar = ab),
    setAllTranslist: (state, translist)  =>  (state.allTranslist = translist),
    setSelectedTrans: (state, transId) => {
      let selTrans = {};
      for(let i=0; i < state.allTranslist.yr.length; i++){
        let iv = state.allTranslist.yr[i].data;
        for(let j=0; j < iv.length; j++){
          let kv = iv[j];
          if(kv.id === transId){
            selTrans = kv;
          }
        }
      }

      state.selectedTrans = selTrans;
      //console.log('^^^^^^^^',state.selectedTrans, transId);
    },
    setAllVideoslist: (state, videoslist)  =>  {
      state.allVideoslist = videoslist;
      state.allVideoslistOrig = videoslist; //do we need to copy?
    },
    setCurrentLogo: (state, clogo) => {
      state.currentLogo = clogo;
    },
    setCurrentProductName: (state, cpn) => {
      state.currentProductName = cpn;
    },
    setCurrentProductUrl: (state, cpu) => {
      state.currentProductUrl = cpu;
    },
    setCurrentProductTxt: (state, cpt) => {
      state.currentProductTxt = cpt;
    },
    setCurrentCreditVal: (state, crval) => {
      state.currentCreditVal = crval;
    },
    setCurrentWalletVal: (state, walval) => {
      state.currentWalletVal = walval;
    },
    setCurrentCxid: (state, cxidval) => {
      state.currentCxid = cxidval;
    },
    clearState: (state) => {
      state.jwt = null;
      state.tk = null;
      state.allTranslist = [];
      state.selectedTrans = {};
      state.allVideoslist = [];
      state.showAppbar = false;
      state.currentLogo = null;
      state.currentProductName = null;
      state.currentProductUrl = null;
      state.currentProductTxts = null;
      state.currentCreditVal = null;
      state.currentWalletVal = null;
      state.enableMenu = true;
    },
    filterCategory: (state, newCat) => {
      state.allVideoslist = newCat;
    },
    resetCategory: (state) => {
      state.allVideoslist = state.allVideoslistOrig;  //do we need to copy?
    },
    setEnableMenu: (state, enmu) => {
      state.enableMenu = enmu;  //do we need to copy?
    },
  },
  actions: {
    async fetchTranslist( { commit, state } ) {
      try{
        const response = await getTranslist(state.jwt, state.tk)
        // console.log('--->', response)
        commit( 'setAllTranslist', response.data.trow )
      }catch(err){
        //set error state here
      }
    },
    async initImp( { state }, pl ) {
      try{
        return await execInitImp(pl, state.jwt, state.tk)
      }catch(err){
        //set error state here
      }
    },
    async fetchWtc( { state }, {cxid} ) {
      try{
        let pl = {
          cxid: cxid
        };
        
        return await retriveWtc(pl, state.jwt, state.tk)
      }catch(err){
        //set error state here
      }
    },
    async fetchSurvey( { state }, {xid} ) {
      try{
        let pl = {
          xid: xid
        };
        return await retriveSurvey(pl, state.jwt)
      }catch(err){
        //set error state here
      }
    },
    async resetPass( { state }, {p1, p2} ) {
      try{
        let pl = {
          pass1: p1,
          pass2: p2
        }
        return await resetPassword(pl, state.jwt)
      }catch(err){
        //set error state here
      }
    },
    async selectTargetTrans( context, transId ) {// use context or destructure commit
      try{
        //console.log('------1=', transId)
        context.commit( 'setSelectedTrans', transId )
      }catch(err){
        //set error state here
      }
    },
    async fetchVideoslist( { commit, state } ) {
      try{
        const response = await getVideoslist(state.jwt, state.tk);

        commit( 'setAllVideoslist', response.data.videos )

        return response;
      }catch(err){
        //set error state here
      }
    },
    async storeJwt( context, jwt ) {// use context or destructure commit
      try{
        context.commit( 'setJwt', jwt )
      }catch(err){
        //set error state here
      }
    },
    async storeTk( context, tk ) {// use context or destructure commit
      try{
        context.commit( 'setTk', tk )
      }catch(err){
        //set error state here
      }
    },
    async storeCurrentVideoIdx( context, vidx ) {// use context or destructure commit
      try{
        context.commit( 'setCurrentVideoIdx', vidx )
      }catch(err){
        //set error state here
      }
    },
    async storeCurrLogo( context, logo ) {// use context or destructure commit
      try{
        context.commit( 'setCurrentLogo', logo )
      }catch(err){
        //set error state here
      }
    },
    async storeCurrProductNm( context, pname ) {// use context or destructure commit
      try{
        context.commit( 'setCurrentProductName', pname )
      }catch(err){
        //set error state here
      }
    },
    async storeCurrProductUrl( context, purl ) {// use context or destructure commit
      try{
        context.commit( 'setCurrentProductUrl', purl )
      }catch(err){
        //set error state here
      }
    },
    async storeCurrProductTxt( context, ptxt ) {// use context or destructure commit
      try{
        context.commit( 'setCurrentProductTxt', ptxt )
      }catch(err){
        //set error state here
      }
    },
    async storeCurrentCreditVal( context, creditVal ) {// use context or destructure commit
      try{
        context.commit( 'setCurrentCreditVal', creditVal )
      }catch(err){
        //set error state here
      }
    },
    async storeCurrentWalletVal( context, walVal ) {// use context or destructure commit
      try{
        context.commit( 'setCurrentWalletVal', walVal )
      }catch(err){
        //set error state here
      }
    },
    async storeCurrentCxid( context, cxidVal ) {// use context or destructure commit
      try{
        context.commit( 'setCurrentCxid', cxidVal )
      }catch(err){
        //set error state here
      }
    },
    async logoutAr( { commit, state } ) {// use context or destructure commit
      try{
        await logout(state.jwt, state.tk)
        commit( 'clearState')
      }catch(err){
        //set error state here
      }
    },
    async clearState( { commit } ) {// use context or destructure commit
      try{
        commit( 'clearState')
      }catch(err){
        //set error state here
      }
    },
    async updateShowAppBar( context, ab ) {// use context or destructure commit
      try{
        context.commit( 'setShowAppbar', ab )
      }catch(err){
        //set error state here
      }
    },
    async rateVideo( {state}, pl ) {
      try{
        const response = await execRateVideo(pl, state.jwt);
        //console.log('-- rate', response);
        // commit( 'setAllVideoslist', response.data.videos )

        return response;
      }catch(err){
        //set error state here
      }
    },
    async completeSurvey( {state}, pl ) {
      try{
        const response = await execCompleteSurvey(pl, state.jwt);
        //console.log('-- rate', response);
        // commit( 'setAllVideoslist', response.data.videos )

        return response;
      }catch(err){
        //set error state here
      }
    },
    async learnMore( {state}, pl ) {
      try{
        return execLearnMore(pl, state.jwt);
      }catch(err){
        //set error state here
      }
    },
    async remainingMinutes( {state} ) {
      try{
        const response = await retrieveDailyMin(state.jwt);
        //console.log('-- rate', response);
        // commit( 'setAllVideoslist', response.data.videos )

        return response;
      }catch(err){
        //set error state here
      }
    },
    async filterCat( {commit, state}, {cat}){
      // console.log('-- cat', cat);
      if(cat === "All Categories"){
        commit('resetCategory');
      }else{
        let cats = state.allVideoslistOrig.filter( x => x.catName === cat );
        // console.log('-- cats', cats);
        commit('filterCategory', cats);
      }
    },
    async enableMenuVal( context, enMu ) {// use context or destructure commit
      try{
        context.commit( 'setEnableMenu', enMu )
      }catch(err){
        //set error state here
      }
    },
  },
  modules: {
  }
})
