<template>
  <transaction-issue-comp msg="wallet" />
</template>

<script>
  import TransactionIssueComp from '../components/TransactionIssue'

  export default {
    name: 'HomeView',

    components: {
      TransactionIssueComp,
    },
  }
</script>
