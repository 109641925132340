<style scoped>
.circleBase {
    border-radius: 50%;
}

.type1 {
    width: 100px;
    height: 100px;
    background: yellow;
    border: 3px solid red;
}
.type2 {
    width: 58px;
    height: 58px;
    background: white;
    border: 1px solid black;
}
.type3 {
    width: 500px;
    height: 500px;
    background: aqua;
    border: 30px solid blue;
}
</style>
<template>
  <v-container>
    <v-row style="height: 65px">
      <v-col md="5" offset-md="3">
        <!-- <v-img
          :src="require('../assets/logo.svg')"
          class="my-3"
          contain
          height="200"
        /> -->
        <v-select 
          :items="categories"
          label="All Categories"
          solo
          v-on:change="changeCat"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="left" cols="auto">
        <v-img :src="currentIconPath" height="50" width="50" class="circleBase type2" />
      </v-col>
      <v-col align="left" align-self="end">
        {{currentPname}}
      </v-col>
      <v-col align="right" align-self="end">
        <v-spacer></v-spacer> 
        <span v-if="currentCredit === 0" style="color:red">Credits {{currentCredit}}</span>
        <span v-if="currentCredit !== 0">Credits {{currentCredit}}</span>
        <!-- <v-spacer></v-spacer>
        {{viewLimitMsg}} -->
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <!-- <v-responsive :aspect-ratio="16/9"> -->
        <v-responsive>
          <div style="border:2px solid black;">
            <v-carousel  hide-delimiters hide-delimiter-background height="auto" v-model="currentIndex" @change="handleChange">       
                <v-carousel-item
                  v-for="(item, i) in allVideoslist"
                  :key="i"
                  reverse-transition="fade-transition"
                  transition="fade-transition" 
                >
                  <router-link :to="item.path+'&ci='+item.xid">
                    <v-img :src="item.pic" />
                  </router-link>
                </v-carousel-item>
            </v-carousel>     
          </div>
        </v-responsive>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4">
          <star-rating :star-size="20" v-model="currentRating" :read-only="true" :round-start-rating="false"></star-rating>
      </v-col>
      <v-col align="right">
        Wallet {{parseInt(currentMemCredits)}}
      </v-col>
    </v-row>
    <v-row v-if="dailyLimit || weeklyLimit || watchLimit">
      <v-col>
        <v-banner
          lines="one"
          color="yellow"
        >
        <div style="text-align:center" v-if="dailyLimit && !weeklyLimit && !watchLimit">
            You have reached the daily max for this ad.
        </div>
        <div style="text-align:center" v-if="watchLimit">
            You have reached the watch max for this ad.
        </div>
        <div style="text-align:center" v-if="weeklyLimit && !watchLimit">
            You have reached the weekly max for this ad.
        </div>
        </v-banner>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import StarRating from 'vue-star-rating'
  import {mapActions, mapState} from 'vuex';

  export default {
    name: 'HomeComp',
    props:['msg'],
    computed: {
      //...mapGetters(["allTodos", "allTranslist"]), - map to a getter
      ...mapState(["allVideoslist", "currentVideoIdx"]), // map directly to a state
      // ...mapActions(['clearState']),
    },
    components: {
      StarRating
    },
    data: () => ({
      model: 0,
      categories: [],
      currentIndex: 0,
      currentRating: 0,
      currentCredit: 0,
      currentMemCredits: 0,
      currentPname: '',
      currentIconPath: '',
      items: [],
      ecosystem: [],
      importantLinks: [],
      whatsNext: [],
      // viewLimitMsg: '',
      dailyLimit: false,
      weeklyLimit: false,
      watchLimit: false
    }),
    methods: {
      ...mapActions(['fetchVideoslist', 'updateShowAppBar', 'filterCat', 'storeCurrLogo', 'storeCurrProductNm', 'storeCurrentCreditVal', 
                      'storeCurrentWalletVal', 'storeCurrentCxid', 'storeCurrProductTxt', 'storeCurrProductUrl', 'enableMenuVal', 'storeCurrentVideoIdx']), 
      handleChange(idx){
        this.currentIndex = idx;
        let currObj = this.allVideoslist[idx];
        if(currObj.viewed){
          this.currentCredit = 0;
          // this.viewLimitMsg = 'View Limit Reached'
        }else{
          this.currentCredit = currObj.credit;
          // this.viewLimitMsg = '';
        }
        this.storeCurrentCxid(currObj.xid);
        this.storeCurrentCreditVal(this.currentCredit);
        this.currentPname = currObj.productName;
        this.storeCurrProductNm(currObj.productName);
        this.storeCurrProductTxt(currObj.productTxt);
        this.storeCurrProductUrl(currObj.productUrl);
        this.currentIconPath = currObj.icon;
        this.storeCurrLogo(currObj.icon);
        this.storeCurrentVideoIdx(idx);
        this.currentRating = parseFloat(parseFloat(this.allVideoslist[idx].stars).toFixed(2));

        this.dailyLimit= currObj.dailyLimit;
        this.weeklyLimit= currObj.weeklyLimit;
        this.watchLimit= currObj.watchLimit;        
      },
      changeCat(x){
        this.filterCat({cat: x});
        this.handleChange(0);
      }
    },
    beforeMount(){
      this.fetchVideoslist().then((v) =>{
        // if( !v ){
        //   this.clearState();
        //   this.$router.push({ path: '/' });
        // }

        //console.log('--->', v);
        if(v.status === 200){
          let idxc = this.currentVideoIdx;
          this.currentIndex = idxc;
          if(v.data.videos[idxc].viewed){
            this.currentCredit = 0;
            this.viewLimitMsg = 'View Limit Reached'
          }else{
            this.currentCredit = v.data.videos[idxc].credit;
            this.viewLimitMsg = '';
          }
          this.storeCurrentCreditVal(this.currentCredit);

          this.storeCurrentCxid(v.data.videos[idxc].xid);
          this.currentRating = parseFloat(parseFloat(v.data.videos[idxc].stars).toFixed(2));
          this.currentPname = v.data.videos[idxc].productName;
          this.storeCurrProductNm(v.data.videos[idxc].productName);
          this.storeCurrProductTxt(v.data.videos[idxc].productTxt);
          this.storeCurrProductUrl(v.data.videos[idxc].productUrl);
          this.currentIconPath = v.data.videos[idxc].icon;
          this.storeCurrLogo(v.data.videos[idxc].icon);

          this.dailyLimit= v.data.videos[idxc].dailyLimit;
          this.weeklyLimit= v.data.videos[idxc].weeklyLimit;
          this.watchLimit= v.data.videos[idxc].watchLimit;

          this.currentMemCredits = v.data.memCredits;
          this.storeCurrentWalletVal(v.data.memCredits);

          this.items = v.data.videos;
          this.categories = v.data.categories;
          this.categories.unshift('All Categories');
        }
      });
    },
    created(){
      this.updateShowAppBar(true);
      this.enableMenuVal(true);
    },
    mounted(){
      // 
    }
  }
</script>
