<template>
  <register-comp msg="settings" />
</template>

<script>
  import RegisterComp from '../components/Register'

  export default {
    name: 'RegisterView',

    components: {
      RegisterComp,
    },
  }
</script>
