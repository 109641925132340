<template>
  <v-form 
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-container>
      <v-row class="mt-6">
        <v-col align="center" align-self="end">
          <v-img
            alt="Adreel Logo"
            class="shrink mr-2"
            contain
            src="@/assets/images/Adheader.png"
            transition="scale-transition"
            width="150"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            :centered="centered"
          >
            {{ text }}

            <template v-slot:action="{ attrs }">
              <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="snackbar = false"
              >
                X
              </v-btn>
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="email"
            :rules="rules.email"
            label="Email"
            required
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <!-- <v-col
          cols="12"
          sm="4"
        > -->
        <v-col>
          <v-text-field
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Password"
            outlined
            counter
            @click:append="show1 = !show1"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col> 
          <v-btn
            :disabled="!valid"
            color="success"
            @click="validate"
            block
          >
            Submit
          </v-btn>

          <!-- <v-btn
            color="error"
            class="mr-4"
            @click="reset"
          >
            Reset
          </v-btn> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col align="right" align-self="end">
          <router-link to="/recover" class="mt-2" style="color:#549E39">Forgot Password</router-link>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center" align-self="end">
          <router-link to="/register" class="mr-4 ml-4 mt-2" style="color:#549E39">Create New Account</router-link>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
  
</template>

<script>
  import {login} from '../service/auth';
  import {mapActions} from 'vuex';

  export default {
    name: 'LoginComp',
    
    data: () => ({
      valid: true,
      show1: false,
      password: '',
      email: '',
      snackbar: false,
      text: '',
      centered: true,
      timeout: 3000,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v && v.length >= 8 || 'Min 8 characters',
        emailMatch: () => (`The email and password you entered don't match`),
        email: [
          v => !!v || 'Required',
          v => /.+@.+/.test(v) || 'E-mail must be valid',
        ],
      },
    }),
    methods: {
      ...mapActions(['storeJwt', 'storeTk', 'updateShowAppBar']), 
      async validate () {
        let v = this.$refs.form.validate()
        if(v){
          let loginModel = {email: this.email, pass: this.password};
          let resp = await login(loginModel);
          if(resp && resp.data.statusCode === 200){
            let bdy = JSON.parse(resp.data.body);
            this.storeJwt(bdy.jwt);
            this.storeTk(bdy.tk);
            if(bdy.reset && bdy.reset === true){
              this.$router.push({ path: '/passReset' })
            }else{
              this.$router.push({ path: '/home' })
            }
          }else if(resp && resp.data.statusCode !== 200){
            this.snackbar = true;
            this.text = 'Invalid Login';
          }else{
            this.snackbar = true;
            this.text = 'Invalid Login';
          }
        }
      },
      async reset () {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      }
    },
    created: function(){
        this.updateShowAppBar(false)
    },
    mounted: function(){
        if( this.$route.query.u && this.$route.query.p ){
          this.email = this.$route.query.u;
          this.password = this.$route.query.p;
        }
    }
  }
</script>
