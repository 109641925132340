<template>
  <transaction-detail-comp msg="wallet" />
</template>

<script>
  import TransactionDetailComp from '../components/TransactionDetail'

  export default {
    name: 'TransactionDetailView',

    components: {
      TransactionDetailComp,
    },
  }
</script>
