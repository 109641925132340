<template>
  <transfer-comp msg="settings" />
</template>

<script>
  import TransferComp from '../components/Transfer'

  export default {
    name: 'TransferView',

    components: {
      TransferComp,
    },
  }
</script>
