import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
import LogoutView from '../views/LogoutView.vue'
import HomeView from '../views/HomeView.vue'
import SearchView from '../views/SearchView.vue'
import SettingsView from '../views/RegisterView.vue'
import Profile from '../views/ProfileView.vue'
import WalletView from '../views/WalletView.vue'
import PlayView from '../views/PlayView'
import RateView from '../views/RateView'
import EarnView from '../views/EarnView'
import SurveyView from '../views/SurveyView'
import TransferView from '../views/TransferView'
import PassResetView from '../views/PassResetView'
import PassRecoverView from '../views/PassRecoverView'
import TransactionDetailView from '../views/TransactionDetailView'
import TransactionIssueView from '../views/TransactionIssueView'
import TransactionSummaryView from '../views/TransactionSummaryView'
import store from '../store';

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: '/',
    name: 'base',
    component: LoginView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },{
    path: '/search',
    name: 'search',
    component: SearchView
  },{
    path: '/register',
    name: 'register',
    component: SettingsView
  },{
    path: '/profile',
    name: 'profile',
    component: Profile
  },{
    path: '/wallet',
    name: 'wallet',
    component: WalletView
  },{
    path: '/play',
    name: 'play',
    component: PlayView
  },{
    path: '/rate',
    name: 'rate',
    component: RateView
  },{
    path: '/survey',
    name: 'survey',
    component: SurveyView
  },{
    path: '/earn',
    name: 'earn',
    component: EarnView
  },{
    path: '/transfer',
    name: 'transfer',
    component: TransferView
  },{
    path: '/transSum/:id',
    name: 'transSum',
    component: TransactionSummaryView
  },{
    path: '/transDetail',
    name: 'transDetail',
    component: TransactionDetailView
  },{
    path: '/transIssue',
    name: 'transIssue',
    component: TransactionIssueView
  },{
    path: '/passReset',
    name: 'passReset',
    component: PassResetView
  },{
    path: '/recover',
    name: 'recover',
    component: PassRecoverView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

window.onpopstate = (()=>{ window.popStateDetected = true; });

router.beforeEach(async (to, from, next) => {
  // console.log(to, from, !store.state.jwt, to.name !== 'login');

  if(window.popStateDetected){
    window.popStateDetected = false;
    //console.log('----> pop state')
    return false;
  }

  if( from.name === 'home' && to.name === 'rate' ){
    return next('/login');
  }

  if( from.name === 'home' && to.name === 'play' && !to.query.vadId){
    return next('/login');
  }

  if( from.name !== 'home' && to.name === 'play' ){
    return next('/login');
  }

  if( from.name !== 'play' && to.name === 'rate' ){
    return next('/login');
  }

  if( (from.name !== 'rate' && from.name !== 'survey') && to.name === 'earn' ){
    return next('/login');
  }

  if (!store.state.jwt && (to.name === 'register' || to.name === 'recover')) {
    return next();
  }

  // make sure the user is authenticated & avoid an infinite redirect
  if (!store.state.jwt && to.name !== 'login') {
      return next( { name: 'login' });
  }
  
  // if (!store.state.jwt && to.name === 'login') {
  //   return next( to );
  // }

  return next();
});

export default router
