<template>
  <v-form 
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-container dense=true>
      <v-row class="mt-6">
        <v-col align="center" align-self="end">
          <v-img
            alt="Adreel Logo"
            class="shrink mr-2"
            contain
            src="@/assets/images/Adheader.png"
            transition="scale-transition"
            width="150"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col> New Account Registration</v-col>
      </v-row>
      <!-- <v-row>
        <v-alert
          v-model="alert"
          dense
          dismissible
          type="error"
      > {{errMsg}}</v-alert>
      </v-row> -->
      <v-row>
        <v-col>
          <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            :centered="centered"
          >
            {{ text }}
            <template v-slot:action="{ attrs }">
              <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
          <v-snackbar
            v-model="snackbar2"
            :timeout="timeout2"
            :centered="centered"
          >
            <v-row align="center" justify="center">
              <v-progress-circular indeterminate color="green"></v-progress-circular>
            </v-row>
          </v-snackbar>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">  
          <!-- <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="acct.name"
                label="Profile Name"
                :rules="[rules.required,rules.min6]"
              >
              </v-text-field>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="acct.email"
                label="Email"
                :rules="rules.email"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
              type="date"
                v-model="dob"
                label="Birth Day (14 yr minimum)"
                :rules=[rules.required]
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                :items="items"
                v-model="acct.gender"
                label="Gender"
                :rules=[rules.required]
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="acct.zip"
                label="Zip Code"
                :rules="[rules.required, rules.equal5]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="acct.phone"
                label="US Phone #"
                :rules="[rules.required, rules.equal12]"
                @input="maskPhone"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="12"
        >
          <v-row>
            <v-container
              class="px-0"
              fluid
            >
              <v-checkbox
                v-model="tncDisclosureCb"
                label="Terms & Conditions"
                @change="tncDisclosureCbClick"
                :rules=[rules.requiredAccept]
              ></v-checkbox>
              <v-checkbox
                v-model="optInDisclosureCb"
                label="Text & SMS text OptIn"
                @change="optInDisclosureCbClick"
                :rules=[rules.requiredAccept]
              ></v-checkbox>
            </v-container>
            <div class="text-center">
              <v-dialog
                v-model="optInDialog"
                width="500"
              >
                <template>             
                </template>

                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    Text & SMS text OptIn Policy
                  </v-card-title>

                  <v-card-text>
                    <b>Data charges and mobile phones </b><br/>
You are responsible for any mobile charges that you may incur for using our Services, including text-messaging and data charges. If you’re unsure what those charges may be, you should ask your service provider before using the Services. 
<br/><br/>
You must always keep the mobile phone number associated with your account up to date.  You will receive unique PIN codes via text to validate each AdReel Credit exchange initiated by your account.  If someone else receives the PIN code, it may be possible for them to fraudulently spend your AdReel Credits.  A valid mobile phone number must be provided to create an AdReel account. 
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      text
                      @click="optInDecisionNo"
                    >
                      I Decline
                    </v-btn>
                    <v-btn
                      color="primary"
                      text
                      @click="optInDecisionYes"
                    >
                      I accept
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>

          </v-row>
          <v-row justify="center">
            <v-dialog
              v-model="tncDialog"
              width="600px"
            >
              <template>
              </template>

              <v-card>
                <v-card-title>
                  <span class="text-h5">AdReel Terms & Conditions</span>
                </v-card-title>
                <v-card-text>
                  
<b>Welcome to the AdReel beta test! </b><br/>
These Terms of Use (or "Terms") govern your use of AdReel. When you create an AdReel account, you agree to these terms. 
<br/><br/>
You also hereby acknowledge that AdReel has not made any representations, promises or guarantees that the Services will ever be announced or made available to anyone in the future and that AdReel has no express or implied obligation to you to announce or introduce the Services.  
<br/><br/>
AdReel may discontinue any Beta Program or Beta Services at any time, in its sole discretion, with or without notice. With respect to the Services, this subsection shall supersede any other terms and conditions contained herein, but only to the extent necessary to resolve conflict. 
<br/><br/>
<b>No Conflicting Loyalty </b><br/>
You may not rent, lease, distribute, or resell the Services, or use the Services, as the basis for developing a competitive solution (or contract with a third party to do so), or remove or alter any of the logos, trademark, patent or copyright notices, confidentiality or proprietary legends or other notices or markings that are on or in the Services. 
<br/><br/>
<b>The Service </b><br/>
The AdReel Beta is a “Service” that allows you to: <br/>

<li>Earn AdReel Credits by watching advertising, rating ads, and answering surveys </li>

<li>Exchange your AdReel Credits with our publishing partner(s) for a variety of rewards, such as ad-free content or in-game currency </li>
<br/>
<b>Feedback </b><br/>
The Services are made available to you for purposes of evaluation and feedback without any compensation or reimbursement of any kind from AdReel. You acknowledge the importance of communication between AdReel and you during your use of the Services and hereby agree to receive related correspondence and updates from AdReel. In the event you request to opt out from such communications, your participation in the Beta Program may be canceled at the sole discretion of AdReel.  
<br/><br/>
During the Beta Program, you will be asked to provide feedback regarding your use of the Services. You acknowledge that AdReel owns any feedback provided, and you hereby grant to AdReel, if for any reason it is further needed, a perpetual, non-revocable, royalty-free worldwide license to use and/or incorporate such feedback into any AdReel product or service (including the Services) at any time at the sole discretion of AdReel. AdReel will not publish feedback in a way that is attributable to you without your consent. 
<br/><br/>
<b>What we provide our advertisers </b><br/>
We provide aggregate reports to advertisers for each campaign displayed on AdReel.  The report includes: 
<li>Count of the number of times ads are viewed </li>
<li>Count of the number of clicks on URL's displayed after each ad </li>
<li>Rate of clicks per impression </li>
<li>Aggregated survey and ratings results </li>
<li>Counts broken out by region, age, and gender </li>
<br/>
<b>Data </b><br/>
AdReel is unlike most free services that are funded by advertising because: 
<br/><br/>
WE DO NOT SHARE OR SELL ANY PERSON-LEVEL DATA WITH 3RD PARTIES TO BETTER TARGET YOU WITH ADS 
<br/><br/>
WE DO NOT TRACK ANYTHING YOU DO BEYOND ADREEL TO BETTER TARGET YOU WITH ADS 
<br/><br/>
WE DO NOT ACCEPT OR USE PERSON-LEVEL DATA FROM ANY 3rd PARTY SOURCE TO TARGET YOU WITH RELEVANT ADS 
<br/><br/>
WE DO NOT DEVELOP AN ANNONYMOUS PROFILE OF YOU BASED ON YOUR ONLINE BEHAVIOR TO PERSONALIZE THE ADS YOU SEE IN ADREEL 
<br/><br/>
We don't need to do all of that because our Service is built on the belief that we don't have to build a machine-learning surveillance capability that tracks you across the internet and the real world in order to deliver you ads that you want to see.  We simply give you the choice to watch the ads you choose, when you choose to watch them. 
<br/><br/>
We may provide our advertisers with a receipt, including every impression in their campaigns, so that our advertisers can validate the amount that we are charging them to show you ads.   
<br/><br/>
Advertisers may receive a timestamped record of every impression for their campaigns.  Each record is completely anonymous with a randomly generated ID for the AdReel member associated with the impression.  The ID cannot be connected to anything beyond the records in the impression receipt for a given campaign. 
<br/><br/>
Advertisers may use the AdReel Advertiser Portal to validate the timestamped impressions presented in the receipt.  Using AdReel member credentials (valid username and password combinations), advertisers can identify the impressions associated with the member.  In most cases, we expect advertisers to also create their own member accounts to execute this function.  
<br/><br/>
<b>Your account </b><br/>
You are responsible for any activity that occurs in your AdReel account. Please keep your account secure by using a strong password that only you know; and remember to close the app completely when you are done using it. 
<br/><br/>
By using the Services, you agree that: 
<li>You will not create more than one account for yourself. </li>
<li>You will not create another account if we have already disabled your account, unless you have our written permission to do so. </li>
<li>You will not log in or attempt to access the Services through unauthorized third-party applications or clients. </li>
<li>You must be at least 14 years old. </li>
<li>You must not be prohibited from receiving any aspect of our Service under applicable laws or engaging in payments related Services if you are on an applicable denied party listing. </li>
<li>We must not have previously disabled your account for violation of law or any of our policies. </li>
<br/>
<b>Data charges and mobile phones </b><br/>
You are responsible for any mobile charges that you may incur for using our Services, including text-messaging and data charges. If you're unsure what those charges may be, you should ask your service provider before using the Services. 
<br/><br/>
You must always keep the mobile phone number associated with your account up to date.  You will receive unique PIN codes via text to validate each AdReel Credit exchange initiated by your account.  If someone else receives the PIN code, it may be possible for them to fraudulently spend your AdReel Credits.  A valid mobile phone number must be provided to create an AdReel account. 
<br/><br/>
<b>You are prohibited from using AdReel to: </b><br/>
<li>Do anything unlawful, misleading, or fraudulent or for an illegal or unauthorized purpose. </li>
<li>Do anything to interfere with or impair the intended operation of the Service. This includes misusing any reporting, dispute, or appeals channel, such as by making fraudulent or groundless reports or appeals. </li>
<li>Attempt to create accounts or access or collect information in unauthorized ways.  This includes creating accounts or collecting information in an automated way without our express permission. </li>
<li>Modify, translate, create derivative works of, or reverse engineer our products or their components. </li>
<br/>
<b>Account limitations </b><br/>
Limitations may be placed on your account to help protect AdReel and AdReel members when we notice restricted activities or activity that appears to us as unusual or suspicious.  
<br/><br/>
You will need to resolve any issues with your account before a limitation can be removed. Normally, this is done after you provide us with the information we request. However, if we reasonably believe a risk still exists after you have provided us that information, we may take action to protect us, our members, a third party, or you from reversals, fees, fines, penalties, legal and/or regulatory risks and any other liability. 
<br/><br/>
<b>Your AdReel Credits </b><br/>
No credit, reversal, or refund of AdReel Credits will be issued for any reason after AdReel Credits have been redeemed; in other words, once you successfully complete a transaction, you may not cancel or reverse the transaction. The only way to use AdReel Credits is to redeem them for offers within our partner publishers' services.  If you violate these Terms in anyway, AdReel reserves the right to cancel your account and eliminate any unredeemed AdReel Credits associated with your account.   
<br/><br/>
<b>Additional rights we retain </b><br/>
<li>You agree that we can download and install updates to the Service on your device. </li>
<li>If you select a username or similar identifier for your account, we may change it if we believe it is appropriate or necessary (for example, if it infringes someone's intellectual property or impersonates another user). </li>
<li>You must obtain written permission from us or under an open source license to modify, create derivative works of, decompile, or otherwise attempt to extract source code from us. </li>
<li>We can refuse to provide or stop providing all or part of the Service to you immediately to protect our community or services, or if you create risk or legal exposure for us, violate these Terms of Use or our policies  </li>
<br/>
<b>Indemnity </b><br/>
You agree, to the extent permitted by law, to indemnify, defend, and hold harmless snappyappy LLC, our affiliates, directors, officers, stockholders, employees, licensors, and agents from and against any and all complaints, charges, claims, damages, losses, costs, liabilities, and expenses (including attorneys' fees) due to, arising out of, or relating in any way to: your access to or use of the Services; and your breach of these Terms. 
<br/><br/>
<b>Disclaimers </b><br/>
We try to keep the Services up and running and free of annoyances. But we make no promises that we will succeed. 
THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE” AND TO THE EXTENT PERMITTED BY LAW WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. IN ADDITION, WHILE snappyappy LLC ATTEMPTS TO PROVIDE A GOOD USER EXPERIENCE, WE DO NOT REPRESENT OR WARRANT THAT: (A) THE SERVICES WILL ALWAYS BE SECURE, ERROR-FREE, OR TIMELY; (B) THE SERVICES WILL ALWAYS FUNCTION WITHOUT DELAYS, DISRUPTIONS, OR IMPERFECTIONS; OR (C) THAT ANY CONTENT, USER CONTENT, OR INFORMATION YOU OBTAIN ON OR THROUGH THE SERVICES WILL BE TIMELY OR ACCURATE. 
snappyappy LLC. TAKES NO RESPONSIBILITY AND ASSUMES NO LIABILITY FOR ANY CONTENT THAT YOU, ANOTHER USER, OR A THIRD PARTY CREATES, UPLOADS, POSTS, SENDS, RECEIVES, OR STORES ON OR THROUGH OUR SERVICES. YOU UNDERSTAND AND AGREE THAT YOU MAY BE EXPOSED TO CONTENT THAT MIGHT BE OFFENSIVE, ILLEGAL, MISLEADING, OR OTHERWISE INAPPROPRIATE, NONE OF WHICH snappyappy LLC WILL BE RESPONSIBLE FOR. 
<br/><br/>
<b>Limitation of liability </b><br/>
TO THE MAXIMUM EXTENT PERMITTED BY LAW, snappyappy LLC. AND OUR MANAGING MEMBERS, SHAREHOLDERS, EMPLOYEES, AFFILIATES, LICENSORS, AGENTS, AND SUPPLIERS WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR MULTIPLE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM: (A) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICES; (B) THE CONDUCT OR CONTENT OF OTHER USERS OR THIRD PARTIES ON OR THROUGH THE SERVICES; OR (C) UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR CONTENT, EVEN IF snappyappy LLC. HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL snappyappy LLC'S AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO THE SERVICES EXCEED THE AMOUNT YOU PAID snappyappy LLC, IF ANY, IN THE LAST 12 MONTHS. 
<br/><br/>
<b>Arbitration, class-action waiver, and jury waiver </b><br/>
PLEASE READ THE FOLLOWING PARAGRAPHS CAREFULLY BECAUSE THEY REQUIRE YOU AND snappyappy LLC. TO AGREE TO RESOLVE ALL DISPUTES BETWEEN US THROUGH BINDING INDIVIDUAL ARBITRATION. <br/>
<ol>
  <li>Applicability of Arbitration Agreement. You and snappyappy LLC. agree that all claims and disputes (whether contract, tort, or otherwise), including all statutory claims and disputes, arising out of or relating to these Terms or the use of the Services that cannot be resolved in small claims court will be resolved by binding arbitration on an individual basis, except that you and snappyappy LLC are not required to arbitrate any dispute in which either party seeks equitable relief for the alleged unlawful use of copyrights, trademarks, trade names, logos, trade secrets, or patents. To be clear: The phrase “all claims and disputes” also includes claims and disputes that arose between us before the effective date of these Terms. </li>
  <li>Arbitration Rules. The Federal Arbitration Act governs the interpretation and enforcement of this dispute-resolution provision. Arbitration will be initiated through the American Arbitration Association (“AAA”) and will be governed by the AAA Consumer Arbitration Rules, available here as of the date of these Terms, or by calling the AAA at 1-800-778-7879. If the AAA is not available to arbitrate, the parties will select an alternative arbitral forum. The rules of the arbitral forum will govern all aspects of this arbitration, except to the extent those rules conflict with these Terms. The arbitration will be conducted by a single neutral arbitrator. Any claims or disputes where the total amount sought is less than $10,000 USD may be resolved through binding non-appearance-based arbitration, at the option of the party seeking relief. For claims or disputes where the total amount sought is $10,000 USD or more, the right to a hearing will be determined by the arbitral forum's rules. Any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. </li>
  <li>Additional Rules for Non-appearance Arbitration. If non-appearance arbitration is elected, the arbitration will be conducted by telephone, online, written submissions, or any combination of the three; the specific manner will be chosen by the party initiating the arbitration. The arbitration will not involve any personal appearance by the parties or witnesses unless the parties mutually agree otherwise. </li>
  <li>Fees. If you choose to arbitrate with snappyappy LLC, you will not have to pay any fees to do so. That is because snappyappy LLC will reimburse you for your filing fee and the AAA's Consumer Arbitration Rules provide that any hearing fees and arbitrator compensation are our responsibility. To the extent another arbitral forum is selected, snappyappy LLC will pay that forum's fees as well. </li>
  <li>Authority of the Arbitrator. The arbitrator will decide the jurisdiction of the arbitrator and the rights and liabilities, if any, of you and snappyappy LLC The dispute will not be consolidated with any other matters or joined with any other cases or parties. The arbitrator will have the authority to grant motions dispositive of all or part of any claim or dispute. The arbitrator will have the authority to award monetary damages and to grant any non-monetary remedy or relief available to an individual under law, the arbitral forum's rules, and the Terms. The arbitrator will issue a written award and statement of decision describing the essential findings and conclusions on which the award is based, including the calculation of any damages awarded. The arbitrator has the same authority to award relief on an individual basis that a judge in a court of law would have. The award of the arbitrator is final and binding upon you and snappyappy LLC </li>
  <li>Waiver of Jury Trial. YOU AND snappyappy LLC WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY. You and snappyappy LLC are instead electing to have claims and disputes resolved by arbitration. Arbitration procedures are typically more limited, more efficient, and less costly than rules applicable in court and are subject to very limited review by a court. In any litigation between you and snappyappy LLC over whether to vacate or enforce an arbitration award, YOU AND snappyappy LLC WAIVE ALL RIGHTS TO A JURY TRIAL, and elect instead to have the dispute be resolved by a judge. </li>
  <li>Waiver of Class or Consolidated Actions. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If, however, this waiver of class or consolidated actions is deemed invalid or unenforceable, neither you nor we are entitled to arbitration; instead all claims and disputes will be resolved in a court as set forth in Section 1 </li>
  <li>Right to Waive. Any rights and limitations set forth in this arbitration agreement may be waived by the party against whom the claim is asserted. Such waiver will not waive or affect any other portion of this arbitration agreement. </li>
  <li>Opt-out. You may opt out of this arbitration agreement. If you do so, neither you nor snappyappy LLC can force the other to arbitrate. To opt out, you must notify snappyappy LLC in writing no later than 30 days after first becoming subject to this arbitration agreement. Your notice must include your name and address, your AdReel username and the email address you used to set up your AdReel account (if you have one), and an unequivocal statement that you want to opt out of this arbitration agreement. You must either mail your opt-out notice to this address: snappyappy LLC, ATTN: Arbitration Opt-out, 2631Laurel Lane NW Kennesaw, GA 30152, or email the opt-out notice to beta_test@myadreel.com. </li>
  <li>Small Claims Court. Notwithstanding the foregoing, either you or snappyappy LLC may bring an individual action in small claims court. </li>
  <li>Arbitration Agreement Survival. This arbitration agreement will survive the termination of your relationship with snappyappy LLC </li>
</ol>
<br/><br/>
<b>Updating these terms </b><br/>
We may change our Service and policies, and we may need to make changes to these Terms so that they accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for example, through our Service) before we make changes to these Terms and give you an opportunity to review them before they go into effect. Then, if you continue to use the Service, you will be bound by the updated Terms. If you do not want to agree to these or any updated Terms, you can delete your account. 
                
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="tncDecisionNo"
                  >
                    I Decline
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="tncDecisionYes"
                  >
                    I Accept
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="12"
        >
          <v-row>
            <v-col> 
              <v-btn
                :disabled="!valid"
                color="success"
                class="mr-4"
                @click="validate"
              >
                Submit
              </v-btn>
              <v-btn
                color="success"
                class="mr-2"
                @click="back"
              >
                Back
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
  import {registerAcct} from '../service/adwsvc'
  export default {
    name: 'RegisterComp',
    
    data: () => ({
      items: [{text:'Male',value:'M'}, {text:'Female',value:'F'}, {text:'Non-Binary',value:'B'}, {text:'Other',value:'O'}],
      initialDate: null,
      valid: true,
      snackbar: false,
      snackbar2: false,
      text: '',
      timeout: 60000,
      timeout2: 60000,
      centered: true,
      dialog: false,
      tncDialog: false,
      optInDialog: false,
      tncDisclosureCb: false,
      optInDisclosureCb: false,
      acct: {
        email: '',
        gender: '',
        phone: '',
        zip: '',
        acceptTerms: '',
        smsEmailOptin: ''
      },
      rules: {
        required: value => !!value || 'Required.',
        requiredAccept: value => !!value || 'Please read and accept to create an account.',
        min6: v => v && v.length >= 6 || 'Min 6 characters',
        equal5: v => v && v.length == 5 || 'Total 5 characters',
        equal12: v => v && v.length == 12 || 'Phone # must be 12 digits, US only #',
        emailMatch: () => (`The email and password you entered don't match`),
        email: [
          v => !!v || 'Required!',
          v => /.+@.+/.test(v) || 'E-mail must be valid and unique',
        ],
      },
    }),
    computed: {
      dob: {
        get() {
          return this.initialDate;
        },
        set(newValue) {
          this.initialDate = newValue;
        },
      },
    },
    methods: {
      optInDisclosureCbClick(){
        if( this.optInDisclosureCb ){
          this.optInDialog = true;
        }else{
          this.optInDisclosureCb = false;
        }
      },
      tncDisclosureCbClick(){
        if( this.tncDisclosureCb){
          this.tncDialog = true;
        }else{
          this.tncDisclosureCb = false;
        }
      },
      optInDecisionYes(){
        this.optInDialog = false;
        this.optInDisclosureCb = true;
      },
      optInDecisionNo(){
        this.optInDialog = false;
        this.optInDisclosureCb = false;
      },
      tncDecisionYes(){
        this.tncDialog = false;
        this.tncDisclosureCb = true;
      },
      tncDecisionNo(){
        this.tncDialog = false;
        this.tncDisclosureCb = false;
      },
      maskPhone: function(x) {
        if(!x) return;
        
        let pn = x.replace(/-/g, "");

        if(pn.length > 6){
          pn = pn.slice(0,3) + '-' + pn.slice(3, 6) + '-' + pn.substring(6);
        }else if(pn.length === 6){
          pn = pn.slice(0,3) + '-' + pn.substring(3) + '-';
        }else if(pn.length === 3){
          pn = pn + '-'
        }else if(pn.length === 4 || pn.length === 5){
          pn = pn.slice(0,3) + '-'
        }
        this.acct.phone = pn;
      },
      validate () {
        if(this.$refs.form.validate() && this.tncDisclosureCb && this.optInDisclosureCb){
          this.acct.dob = this.dob;
          this.acct.acceptTerms = 'Y';
          this.acct.smsEmailOptin = 'Y';
          this.snackbar2 = true; 

          registerAcct(this.acct).then( resp => {     
            // console.log(resp)       
            if(resp && resp.status === 200){
              this.snackbar2 = false; 
              this.snackbar = true;
              this.text = 'Temp password was SMSed to: ' + this.acct.phone + '\n\r and emailed to: ' + this.acct.email + ' (check junk folder as well)'
              setTimeout( () => {
                this.$router.push({ path: '/' });
              },60000)
            }
          })
          .catch(err => {
            // console.log(err)
            if(err.response.data.errorCode === '1006'){
                this.text = 'Invalid Birthday, must be 14 years or older.';
            }else if(err.response.data.errorCode === '1004'){
                this.text = 'Invalid Zip code.';
            }else if(err.response.data.errorCode === '1003' || err.response.data.errorCode === '1002'|| err.response.data.errorCode === '1005'){
                this.text = 'Invalid information submitted, please try again.';
            }else{
                this.text = 'Unexpected error, please try again or contact beta_test@myadreel.com';
            }
            this.snackbar2 = false; 
            this.snackbar = true;
          });
        }
      },
      back() {
        this.$router.push({ path: '/' });
      },
      resetValidation () {
        this.$refs.form.resetValidation();
      },
    },
    created() {
      let now = new Date();
      now.setFullYear( now.getFullYear() - 14 );
      this.initialDate = now.getFullYear() + "-" + ("0" + (now.getMonth() + 1)).slice(-2) + "-" + ("0" + now.getDate()).slice(-2);
    },
    mounted(){
      // this.snackbar2 = true; 
    }
  }
</script>
