<template>
  <survey-comp msg="Welcome to Your survey"/>
</template>

<script>
  import SurveyComp from '../components/Survey'

  export default {
    name: 'SurveyView',

    components: {
      SurveyComp,
    },
  }
</script>
