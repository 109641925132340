<template>
  <rate-comp msg="Welcome to Your rating"/>
</template>

<script>
  import RateComp from '../components/Rate'

  export default {
    name: 'RateView',

    components: {
      RateComp,
    },
  }
</script>
