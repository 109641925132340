<style scoped>
.circleBase {
    border-radius: 50%;
}
.type2 {
    width: 58px;
    height: 58px;
    background: white;
    border: 1px solid black;
}
</style>

<template>
  <v-form 
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-container>
      <v-row>
        <v-col> <v-banner align="center" align-self="center" style="backgroundColor:#549E39;color:white;">Please rate the ad to earn your Credit. </v-banner></v-col>
      </v-row>
      <v-row>
        <v-col align="left" cols="auto">
          <v-img :src="currentLogo" height="50" width="50" class="circleBase type2" />
        </v-col>
        <v-col align="left" align-self="end">
          {{currentProductName}}
        </v-col>
        <v-col align="right" align-self="end">
          <!-- <v-spacer></v-spacer> -->
          <!-- <a :href="currentProductUrl" target="_blank">
            {{currentProductTxt === null || currentProductTxt.trim() === '' ? 'Learn More' : currentProductTxt}}
          </a> -->
          <!-- <a @click={learnMoreClk} >
            {{currentProductTxt === null || currentProductTxt.trim() === '' ? 'Learn More' : currentProductTxt}}
          </a> -->
          <v-btn
            @click="learnMoreClk"
            x-small
          >
            {{currentProductTxt === null || currentProductTxt.trim() === '' ? 'Learn More' : currentProductTxt}}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
          <!-- <v-col
            cols="12"
            md="4"> -->
          <v-col>
            <div style="display: flex;justify-content: center;border: 1px solid green;height:200px">
              <star-rating :increment="0.5" v-model="boundRating" ></star-rating>
            </div>
          </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div style="display: flex;justify-content: center;">
         <v-btn
            :disabled="boundRating===0"
            color="black"
            @click="submitRating"
            width="70%"
            class="white--text"
          >
            Submit
          </v-btn>
          </div>
        </v-col>
      </v-row>

    </v-container>
  </v-form>
  
</template>

<script>
  import StarRating from 'vue-star-rating'
  import {mapActions, mapState} from 'vuex';
  export default {
    name: 'RateComp',
    computed: {
      //...mapGetters(["allTodos", "allTranslist"]), - map to a getter
      ...mapState(["currentLogo", "currentProductName", "currentProductUrl", "currentProductTxt"]), // map directly to a state
      // ...mapActions(['clearState']),
    },
    components: {
      StarRating
    },
    data: () => ({
      valid: true,
      boundRating: 0,
      xid: 0,
      wixid: 0
    }),
    methods: {
      ...mapActions(['rateVideo', 'learnMore', 'updateShowAppBar', 'enableMenuVal']), 
      submitRating(){
        let pl = {
          rating: this.boundRating,
          xid: this.xid,
          wxid: this.wixid
        };
        if(parseFloat(this.boundRating) > 0){
          this.rateVideo(pl).then( (resp) => {
            if(resp && resp.data && resp.data.msg === 'Success' && resp.data.survey == true){
              this.$router.push({ path: '/survey', query: { xid: this.xid, wixid: this.wixid} });
            }else if(resp && resp.data && resp.data.msg === 'Success'){
              this.$router.push({ path: '/earn' })
            }else{
              this.$router.push({ path: '/earn' })  //todo error msg
            }
          })          
        }
      },
      learnMoreClk(){
        let pl = {
          xid: this.xid
        };

        this.learnMore(pl);
        window.open(this.currentProductUrl, '_blank', 'noreferrer');
      }
    },
    created() {
      this.updateShowAppBar(true)
      this.enableMenuVal(false);
      if( !this.$route.query.xid || !this.$route.query.wixid){
        this.$router.push({ path: '/home' });
      }else{
        this.xid = this.$route.query.xid;
        this.wixid = this.$route.query.wixid;
      }
    }
  }
</script>
