<template>
  <transaction-summary-comp msg="wallet" />
</template>

<script>
  import TransactionSummaryComp from '../components/TransactionSummary'

  export default {
    name: 'TransactionSummaryView',

    components: {
      TransactionSummaryComp,
    },
  }
</script>
