<template>
    <v-container> 
    </v-container> 
</template>

<script>
  import {mapActions} from 'vuex';

  export default {
    name: 'LogoutComp',
    
    data: () => ({
    }),
    methods: {
      ...mapActions(['logoutAr'])
    },
    created() {
      this.logoutAr();
      
      //todo - invalidate jwt & token
      this.$router.push({ path: '/' })
      // this.$router.go('/');
    }
  }
</script>
