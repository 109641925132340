<template>
  <v-form 
  ref="form"
  v-model="valid"
  lazy-validation
  >
  <v-container>
      <v-row>
        <v-col> AdReel Update Password </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            :centered="centered"
          >
            {{ text }}

            <template v-slot:action="{ attrs }">
              <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="snackbar = false"
              >
                X
              </v-btn>
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="pass1"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Enter Password"
            hint="At least 8 characters"
            counter
            @click:append="show1 = !show1"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="pass2"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Re-Enter Password"
            hint="At least 8 characters"
            counter
            @click:append="show1 = !show1"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col> 
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="validate"
          >
            Update Password
          </v-btn>

          <!-- <v-btn
            color="success"
            class="mr-4"
            @click="home"
          >
            Continue
          </v-btn> -->
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
  import {mapActions, mapState} from 'vuex';
  export default {
    name: 'PassResetComp',
    props:['msg'],
    computed: {
      //...mapGetters(["allTodos", "allTranslist"]), - map to a getter
      ...mapState(["jwt"]), // map directly to a state
    },
    data: () => ({
      valid: true,
      show1: false,
      pass1: '',
      pass2: '',
      snackbar: false,
      text: '',
      centered: true,
      timeout: 2000,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v && v.length >= 8 || 'Min 8 characters'
      },
    }),
    methods: {
      ...mapActions(['storeJwt', 'storeTk', 'resetPass']), 
      async validate () {
        let v = this.$refs.form.validate()
        if(this.pass1 !== this.pass2){
          this.snackbar = true;
          this.text = 'Passwords Do Not Match!'
          return;
        }
        if(v){
          let respData = await this.resetPass({p1:this.pass1, p2:this.pass2});
          if(respData.status === 200){
            this.$router.push({ path: '/home' })
          }else{
            this.snackbar = true;
            this.text = 'Password was not updated.  Please try again.'
          }
        }
      },
      async home () {
        // this.$refs.form.reset()
        // this.$refs.form.resetValidation()
        this.$router.push({ path: '/home' })
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
    },
  }
</script>