<style scoped>
.circleBase {
    border-radius: 50%;
}
.type2 {
    width: 58px;
    height: 58px;
    background: white;
    border: 1px solid black;
}

/* Centered text */
.centered {
  position: absolute;
  top: 360px;
  left: 52%;
  transform: translate(-50%, -50%);
}
</style>

<template>
  <v-form 
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-container>
      <v-row>
        <v-col> 
          <v-banner align="center" align-self="center" style="backgroundColor:#549E39;color:white;">
              YOU EARNED:
          </v-banner>
        </v-col>
      </v-row>
      <v-row>
        <v-col style="display: flex;justify-content: center;border: 0px solid green;width:80%;">
          <div style="font-size: 30px; color: black">{{this.currentCreditVal}} Credits</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col style="display: flex;justify-content: center;border: 0px solid green;width:80%;">
          <div style="width:95px;height:79px;">
            <v-img :src="require('../assets/images/ArrowDown.png')" />
          </div>
        </v-col>
      </v-row>
      <v-row>
          <v-col style="display: flex;justify-content: center;border: 0px solid green;width:80%;">
            <div style="width:239px;height:222px;">
              <v-img :src="require('../assets/images/WalletClear.png')" />
            </div>
            <div class="centered" style="font-size: 30px; color: #549E39">{{this.currentWalletVal}}</div>
          </v-col>
      </v-row>
      <v-row>
        <v-col> 
          <v-banner v-if="!timeLimit" align="center" align-self="center" style="backgroundColor:#549E39;color:white;">
            AdReel view time remaining today: {{this.remainTime}}
          </v-banner>
          <v-banner  v-if="timeLimit" align="center" align-self="center" style="backgroundColor:#549E39;color:white;">
            Congrats you’ve hit your max!  Check in tomorrow to earn more.
          </v-banner>
          <v-spacer></v-spacer>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div style="display: flex;justify-content: center;">
         <v-btn
            color="black"
            @click="submitOk"
            width="70%"
            class="white--text"
          >
            OK
          </v-btn>
          </div>
        </v-col>
      </v-row>

    </v-container>
  </v-form>
  
</template>

<script>
  import {mapActions, mapState} from 'vuex';
  export default {
    name: 'EarnComp',
    computed: {
      //...mapGetters(["allTodos", "allTranslist"]), - map to a getter
      ...mapState(["currentLogo", "currentProductName", "currentCreditVal", "currentWalletVal"]), // map directly to a state
      //...mapActions(['clearState']),
    },
    components: {
    },
    data: () => ({
      valid: true,
      remainTime: 0,
      timeLimit: false
    }),
    methods: {
      ...mapActions(['updateShowAppBar', 'enableMenuVal', 'remainingMinutes']), 
      submitOk(){
        this.$router.push({ path: '/home' });
      }
    },
    created() {
      this.enableMenuVal(true);

      // let rem = this.remainDailyTime;
      // let mins = Math.floor(rem / 60);
      // let secs = rem - (mins * 60);
      // this.remainTime = mins + ' minutes ' + secs + ' seconds ';
      this.remainingMinutes().then((v) =>{
        this.remainTime = v.data.remaining;
        this.timeLimit = v.data.watched >= 1200 ? true : false;
      });
    }
  }
</script>
