<template>
  <login-comp msg="Welcome to Your login"/>
</template>

<script>
  import LoginComp from '../components/Login'

  export default {
    name: 'LoginView',

    components: {
      LoginComp,
    },
  }
</script>
