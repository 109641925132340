<template>
  <v-app>
    <v-app-bar
      app
      color="#F0F0F0"
      v-if="showAppbar"
    >
      <div class="d-flex align-center">
        <v-img
          alt="Adreel Logo"
          class="shrink mr-2"
          contain
          src="@/assets/images/Adheader.png"
          transition="scale-transition"
          width="150"
        />
      </div>

      <v-spacer></v-spacer>

     
      <div class="text-center">
        <v-menu
          open-on-hover
          offset-y
          v-if="enableMenu"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#549E39"
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiMenu }}</v-icon>
            </v-btn>          
          </template>

          <v-list>
            <!-- <v-list-item
              v-for="(item, index) in items"
              :key="index"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title> -->
            <v-list-item v-if="jwt">
              <router-link to="/home">Home</router-link>
            </v-list-item>
            <v-list-item v-if="jwt">
              <router-link to="/wallet">Wallet</router-link>
            </v-list-item>
            <v-list-item v-if="jwt">
              <router-link to="/profile">Profile</router-link>
            </v-list-item>
            <v-list-item v-if="jwt">
              <router-link to="/logout">Logout</router-link>
            </v-list-item>  
            <v-list-item v-else>
                <router-link to="/">Login</router-link>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <v-main>
      <!-- <nav>
        <router-link to="/">Login</router-link> |
        <router-link to="/home">Home</router-link> |
        <router-link to="/search">Search</router-link> |
        <router-link to="/settings">Settings</router-link> |
        <router-link to="/wallet">Wallet</router-link> |
        <router-link to="/profile">Profile</router-link> |
        <router-link to="/about">About</router-link> |
        <router-link to="/play">Play</router-link> |
        <router-link to="/rate">Rate</router-link>
      </nav> -->
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import {
    mdiMenu,
    mdiPencil,
    mdiShareVariant,
    mdiDelete,
  } from '@mdi/js'
  import {mapState} from 'vuex';
export default {
  name: 'App',
  computed: {
      //...mapGetters(["allTodos", "allTranslist"]), - map to a getter
      ...mapState(["jwt", "tk", "showAppbar", "enableMenu"]), // map directly to a state
  },
  data: () => ({
    icons: {
        mdiMenu,
        mdiPencil,
        mdiShareVariant,
        mdiDelete,
      },
      items: [
        { title: 'Login' },
        { title: 'Logout' },
      ],
  }),
};
</script>
