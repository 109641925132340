<template>
  <profile-comp msg="Welcome to Your profile"/>
</template>

<script>
  import ProfileComp from '../components/Profile'

  export default {
    name: 'ProfileView',

    components: {
      ProfileComp,
    },
  }
</script>
