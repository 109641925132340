<template>
  <v-container>
    <!-- <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.svg')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          <h1>{{ msg }} </h1>
        </h1>
      </v-col>
    </v-row> -->
    <v-btn
      color="error"
      class="mr-4"
      @click="transfer"
    >
      Transfer Credits
    </v-btn>

    <br/><br/>
    
    <v-simple-table>
      <template v-slot:default>
        <thead>Recent Transactions</thead>
        <thead style="background:#4caf50;color:white">
          <tr>
            <th class="text-left">
              App
            </th>
            <th class="text-left">
              Transaction Date
            </th>
            <th class="text-left">
              Credits
            </th>
            <th class="text-left">
              Item Purchased
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="tx in txs.recent"
            :key="tx.id"
          >
            <td>{{ tx.name }}</td>
            <td>{{ tx.dt }}</td>
            <td>{{ tx.credits }}</td>
            <td>{{ tx.merch }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <br/><br/>

    <div>2022 Transactions</div>
    <v-expansion-panels>
      <v-expansion-panel
        v-for="(tx,i) in txs.yr"
        :key="i"
      >      
        <v-expansion-panel-header style="background:#4caf50;color:white">
          <table style="width:100%"><tr>
            <td style="width:30%">{{tx.month}}</td>
            <td style="width:30%"># Transactions {{tx.data.length}}</td>
            <td>Total {{tx.ttl}}</td>
          </tr></table>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    App
                  </th>
                  <th class="text-left">
                    Transaction Date
                  </th>
                  <th class="text-left">
                    Credits
                  </th>
                  <th class="text-left">
                    Item Purchased
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="yrtx in tx.data"
                  :key="yrtx.id"
                >
                  <td>{{ yrtx.name }}</td>
                  <td>{{ yrtx.dt }}</td>
                  <td>{{ yrtx.credits }}</td>
                  <td>{{ yrtx.merch }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
  export default {
    name: 'TransactionDetailComp',
    props:['msg'],

    data: () => ({
      txs: {
        recent: [
        {
          id: 1,
          name: "App1",
          dt: "01-02-2022",
          credits: "12",
          merch: "vikings ep 1"
        },
        {
          id: 2,
          name: "App2",
          dt: "02-02-2022",
          credits: "34",
          merch: "10 gems"
        }],
        yr:[
        {
          id: 1,
          month: "January",
          ttl: "$40",
          data: [
            {
              name: "App1",
              dt: "01-02-2022",
              credits: "12",
              merch: "vikings ep 1"
            }
          ],
        },
        {
          id: 2,
          month: "February",
          ttl: "$20",
          data: [
            {
              name: "App2",
              dt: "02-02-2022",
              credits: "34",
              merch: "10 gems"
            }
          ]
        },
        {
          id: 2,
          month: "March",
          ttl: "$34",
          data: [
            {
              name: "App2",
              dt: "03-01-2022",
              credits: "34",
              merch: "10 gems"
            },
            {
              name: "App21",
              dt: "03-02-2022",
              credits: "18",
              merch: "1 gems"
            }
          ]
        }
        ],
      }, 
      ecosystem: [
        
      ],
      importantLinks: [
        
      ],
      whatsNext: [
        
      ],
    }),
    methods: {
      transfer() {
        this.$router.push({ path: '/transfer' })
      }
    }
  }
</script>
