<template>
  <v-container>
    <!-- <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.svg')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          <h1>{{ msg }} </h1>
        </h1>
      </v-col>
    </v-row> -->
  
    <br/><br/>
    
    <v-simple-table>
      <template v-slot:default>
        <thead>Selected {{targetAppMame}} Transaction</thead>
        <thead style="background:#4caf50;color:white">
          <tr>
            <th class="text-left">
              Transaction Date
            </th>
            <th class="text-left">
              Credits
            </th>
            <th class="text-left">
              Item Purchased
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ selectedTrans.dt }}</td>
            <td>{{ selectedTrans.credits }}</td>
            <td>{{ selectedTrans.merch }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <br/><br/>

    <v-simple-table>
      <template v-slot:default>
        <thead>{{targetAppMame}} Transaction History</thead>
        <thead style="background:#4caf50;color:white">
          <tr>
            <th class="text-left">
              Transaction Date
            </th>
            <th class="text-left">
              Credits
            </th>
            <th class="text-left">
              Item Purchased
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="tx in txs"
            :key="tx.id"
          >
            <td>{{ tx.dt }}</td>
            <td>{{ tx.credits }}</td>
            <td>{{ tx.merch }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import {mapState} from 'vuex';
  export default {
    name: 'TransactionSummaryComp',
    props:['msg'],
    computed: {
      //...mapGetters(["allTodos", "allTranslist"]), - map to a getter
      ...mapState(["allTranslist", "selectedTrans", "allVideoslist"]), // map directly to a state
    },
    data: () => ({
      txs: {
        target: [],
      }, 
      targetAppMame: '',
      ecosystem: [
        
      ],
      importantLinks: [
        
      ],
      whatsNext: [
        
      ],
    }),
    methods: {
      
    },
    created() {
      // this.vadId = this.$route.query.vadId
      // let media = this.allVideoslist;// JSON.parse(sessionStorage.getItem("media"));
      // console.log('------>mdedia=', media);
      // media.forEach(e => {
      //   if(e.key === this.$route.query.vadId){
      //     console.log('------> found', e);
      //     this.vad = e.video;
      //   }else{
      //     console.log('------> found', this.$route.query.vadId, e.key);
      //   }
      // });

      // this.vad = "https://dpkr42xyz7pu9.cloudfront.net/automotive/Volkswagen_Volkswagon_15.mov"

      // if(this.$route.query.vad === 'Ford_F-Series'){
      //   this.vad = 'https://dpkr42xyz7pu9.cloudfront.net/automotive/Ford_F-Series_15.mov'
      // }else  if(this.$route.query.vad === 'Ford_F-Series1'){
      //   this.vad = 'https://dpkr42xyz7pu9.cloudfront.net/automotive/Ford_F-Series_15.mov'
      // }else{
      //   this.vad = 'https://d30buq51hpj0t9.cloudfront.net/foodanddrink/5HourEnergy_ExtraStrength_15.mp4'
      // }
    },
    beforeMount() {

    },
    beforeUnmount(){

    },
    beforeDestroy() {
      
    }
  }
</script>