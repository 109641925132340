<template>
  <search-comp msg="Welcome to Your search"/>
</template>

<script>
  import SearchComp from '../components/Search'

  export default {
    name: 'SearchView',

    components: {
      SearchComp,
    },
  }
</script>
