import axios from "axios"
import {baseEp} from "../utils/appUrl"

/* config axios */
const araxios = axios.create({
  // baseURL: ``,
  timeout: 10000, 
  // headers: { 'x': 'x' }
});

const requestHandler = request => {
  // Token will be dynamic so we can use any app-specific way to always
  // request.headers['x-auth-token'] = ""
  return request;
};

const responseHandler = response => {
  // console.log('resp=', response);
  if (response.status === 401 || response.status === 403) {
      window.location = '/';
  }

  return response;
};

const errorHandler = error => {
  if (error.response.status === 401 || error.response.status === 403) {
      window.location.href = '/';
  }else{
      return Promise.reject(error);
  }
};

araxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

araxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);




export const getTranslist = (jwtv) => {
  try{
    // let jwtv = sessionStorage.getItem('jwt');
    // let tkv = sessionStorage.getItem('tk');
    const config = {
      headers:{
        Authorization: jwtv
        // Tk: tkv
      }
    };
    return araxios.post( baseEp + "/tr", {}, config);
    // if(resp && resp.status === 200 && resp.data && resp.data.statusCode === 200){
    //   console.log('translist 200 resp=', resp);
    //   return resp.data;
    // }
    // console.log('translist non 200 resp=', resp);
  }catch(err){
    console.debug("getTranslist err=", err);
    throw err;
  }
}

export const getVideoslist = (jwtv, tkv) => {
  try{
    const config = {
      headers:{
        Authorization: jwtv,
        tk: tkv
      }
    };

    return araxios.post( baseEp + "/vdo", {}, config);
    // if(resp && resp.status === 200 && resp.data && resp.data.statusCode === 200){
    //   console.log('translist 200 resp=', resp);
    //   return resp.data;
    // }
    // console.log('translist non 200 resp=', resp);
  }catch(err){
    console.debug("getVideoslist err=", err);
    throw err;
  }
}

export const execRateVideo = (payload, jwtv) => {
  try{
    const config = {
      headers:{
        Authorization: jwtv
        // Tk: tkv
      }
    };

    return araxios.post( baseEp + "/rt", payload, config);
  }catch(err){
    console.debug("execRateVideo err=", err);
    throw err;
  }
}


export const execCompleteSurvey = (payload, jwtv) => {
  try{
    const config = {
      headers:{
        Authorization: jwtv
        // Tk: tkv
      }
    };

    return araxios.post( baseEp + "/cs", payload, config);
  }catch(err){
    console.debug("execCompleteSurvey err=", err);
    throw err;
  }
}

export const execLearnMore = (payload, jwtv) => {
  try{
    const config = {
      headers:{
        Authorization: jwtv
        // Tk: tkv
      }
    };

    return araxios.post( baseEp + "/lm", payload, config);
  }catch(err){
    console.debug("execLearnMore err=", err);
    throw err;
  }
}

export const execInitImp = (payload, jwtv) => {
  try{
    const config = {
      headers:{
        Authorization: jwtv
        // Tk: tkv
      }
    };

    return araxios.post( baseEp + "/wt", payload, config);
  }catch(err){
    console.debug("execInitImp err=", err);
    throw err;
  }
}

export const retriveWtc = (payload, jwtv) => {
  try{
    const config = {
      headers:{
        Authorization: jwtv
        // Tk: tkv
      }
    };

    return araxios.post( baseEp + "/wtc", payload, config);
  }catch(err){
    console.debug("retriveWtc err=", err);
    throw err;
  }
}

export const retriveSurvey = (payload, jwtv) => {
  try{
    const config = {
      headers:{
        Authorization: jwtv
        // Tk: tkv
      }
    };

    return araxios.post( baseEp + "/sv", payload, config);
  }catch(err){
    console.debug("retriveSurvey err=", err);
    throw err;
  }
}

// araxios.get(url, config)
//   .then(res=> console.log(res))
//   .catch(err=> console.log(err))
// araxios.post(url, data, config)
//   .then(res => console.log(res))
//   .catch(err => console.log(err))

export const logout = async (jwtv, tkv) => {
  try{
    const config = {
      headers:{
        Authorization: jwtv,
        tk: tkv 
      }
    };
    
    await araxios.post( baseEp + "/lgo", {}, config);
    //redirect to login
  }catch(err){
    //console.debug("login err=", err);
    return null;
  }
}

export const recoverPass = (emailx) => {
  return araxios.post( baseEp + "/pr", {
    email: emailx
  });
  // .then(function (response) {
  //   console.log(response);
  // })
  // .catch(function (error) {
  //   console.log(error);
  // });
}

export const registerAcct = (usr) => {
  return araxios.post( baseEp + "/rg", {
    acct: usr
  });
}

export const retrieveProfile = (jwtv) => {
  const config = {
    headers:{
      Authorization: jwtv
    }
  };
  return araxios.post( baseEp + "/gp", {
  }, config);
}

export const retrieveDailyMin = (jwtv) => {
  const config = {
    headers:{
      Authorization: jwtv
    }
  };
  return araxios.post( baseEp + "/wd", {}, config);
}


export const updateProfile = (pl, jwtv) => {
  const config = {
    headers:{
      Authorization: jwtv
    }
  };
  return araxios.post( baseEp + "/up", pl, config);
}

export const updateCredentials = (pl, jwtv) => {
  const config = {
    headers:{
      Authorization: jwtv
    }
  };
  return araxios.post( baseEp + "/up", pl, config);
}

export const resetPassword = async (pl, jwtv) => {
  try{
    const config = {
      headers:{
        Authorization: jwtv
        // Tk: tkv
      }
    };
    let resp = await araxios.post( baseEp + "/rp", pl, config);
    return resp;
  }catch(err){
    return err.response;
  }
}