<template>
  <v-container style="width: 100%;">
    <v-row>
      <v-col>
        <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
          :centered="centered"
        >
          {{ text }}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="blue"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              X
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row> 
    <v-row>
      <v-col md="4">
        <v-btn variant="flat" @click="cancel">
          Cancel
        </v-btn>
      </v-col>
      <v-col align="right">
      </v-col>
    </v-row>
    <v-row>
    <div style="width: 100%; max-width: 1280px; margin: 0 auto; margin-top: 5px;">  
        <video
          playsinline
          webkit-playsinline
          ref="playerv"
          id="player"
          class="video-js"
          preload="auto"
          data-setup="{}"
        >
          <source :src="vad" type="video/mp4" />
          <p class="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading to a
            web browser that
            <a href="https://videojs.com/html5-video-support/" target="_blank"
              >supports HTML5 video</a>
          </p>
        </video>     
      </div>
    </v-row>
    </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import videojs from 'video.js';
import 'video.js/dist/video-js.css'

  export default {
    name: 'PlayComp',
    props:['msg'],
    computed: {
      //...mapGetters(["allTodos", "allTranslist"]), - map to a getter
      ...mapState(["allTranslist", "selectedTrans", "allVideoslist"]), // map directly to a state
    },
    data: () => ({
      player: null,
      snackbar: false,
      text: '',
      centered: true,
      timeout: 2000,
      previousTime: 0,
      currentXid: 0,
      supposedCurrentTime: 0,
      txs: {
        target: [],
      }, 
      targetAppMame: '',
      ecosystem: [
        
      ],
      importantLinks: [
        
      ],
      whatsNext: [
        
      ],
    }),
    methods: {
      ...mapActions(['updateShowAppBar', 'initImp']), 
      cancel(){
        this.$router.push({ path: '/home' });
      }
    },
    created() {
      let media = this.allVideoslist;
      media.forEach(e => {
        if(e.key === this.$route.query.vadId){
          // console.log('------> found', e);
          this.vad = e.video;
          this.currentXid = e.xid
        }
      });
    },
    beforeMount(){
      this.updateShowAppBar(false);
    },
    mounted() {
      let that = this;

      // https://gist.github.com/alexrqs/a6db03bade4dc405a61c63294a64f97a
      this.player = videojs(this.$refs.playerv, {
        fluid: true
      }, () => {
        // this.player.log('onPlayerReady', this);
      });

      this.player.on("ended",() => {
        // console.debug('The video has ended');
        this.initImp({ cxid: this.$route.query.ci }).then((v) => {
          if(v.status === 200){
            this.$router.push({ path: '/rate', query: { xid: this.currentXid, wixid:  v.data.wixid} });
          }else{
            this.$router.push({ path: '/home' });
          }
        });
      });
      this.player.ready(() => {
        // console.log('----->playerready');
        // this.player.requestFullscreen();
        this.player.play();
      });
      this.player.on('seeking', function () {
        var delta = this.currentTime() - that.supposedCurrentTime;
        if (Math.abs(delta) > 0.01) {
          // console.log("Seeking is disabled");
          this.currentTime(that.supposedCurrentTime);
        }
        // this.play();
        // console.log('----->seeking');
      });
      this.player.on('seeked', function () {
        this.play();
        // console.log('----->seeked');
      });
      this.player.on('waiting', function () {
        // console.log('----->waiting');
      });
      this.player.on('pause', function () {
        // console.log('----->pause');
      });
      this.player.on('stalled', function () {
        // console.log('----->stalled');
      });
      this.player.on('error', function () {
        // console.log('----->error');
      });
      this.player.on('suspend', function () {
        // console.log('----->suspend');
        this.play();
      });
      this.player.on('abort', function () {
        // console.log('----->abort');
      });
      this.player.on('canplay', function(){
        // console.log('canplay event fired');
      });
      this.player.on('canplaythrough', function(){
        // console.log('canplaythrough event fired');
      });
      this.player.on('timeupdate', function(){
        if (!this.seeking) {
          that.supposedCurrentTime = this.currentTime;
        }
        // console.log('timeupdate event fired');
      });
    },
    beforeDestroy() {
      if (this.playerv) {
        this.playerv.dispose();
      }
    }
  }
</script>
