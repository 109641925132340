<template>
  <pass-recover-comp msg="wallet" />
</template>

<script>
  import PassRecoverComp from '../components/PassRecover'

  export default {
    name: 'PassRecoverView',

    components: {
      PassRecoverComp,
    },
  }
</script>
