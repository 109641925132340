<style scoped>
.circleBase {
    border-radius: 50%;
}
.type2 {
    width: 58px;
    height: 58px;
    background: white;
    border: 1px solid black;
}
</style>

<template>
  <v-form 
    ref="form"
    lazy-validation
  >
    <v-container>
      <v-row>
        <v-col> <v-banner align="center" align-self="center" style="backgroundColor:#549E39;color:white;">Please complete the survey to earn your Credit. </v-banner></v-col>
      </v-row>
      <v-row>
        <v-col align="left" cols="auto">
          <v-img :src="currentLogo" height="50" width="50" class="circleBase type2" />
        </v-col>
        <v-col align="left" align-self="end">
          {{currentProductName}}
        </v-col>
        <v-col align="right" align-self="end">
          <v-btn
            @click="learnMoreClk"
            x-small
          >
            {{currentProductTxt === null || currentProductTxt.trim() === '' ? 'Learn More' : currentProductTxt}}
          </v-btn>
        </v-col>
      </v-row>
      <v-row> {{ msg }}</v-row>
      <v-row>
          <!-- <v-col
            cols="12"
            md="4"> -->
          <v-col>
            <!-- <div style="display: flex;justify-content: center;border: 1px solid green;height:200px"> -->
            <div>
              <div v-for="(val, i) in questions" :key="i" style="margin-left: 300; border-top: 1px solid">
                
                {{ val.question }}
                
                <v-radio-group v-model="qa[i]">
                  <!-- <div v-for="(val2, j) in val.answers" :key="j"> -->
                    <!-- <input type="radio" 
                      :name="labelName" 
                      :value="val" 
                      :checked="val == value" 
                    > -->

                    <div><v-radio :label="val.answers[0].answer" :value="val.qxid +'|'+ val.answers[0].axid +'|'+ val.answers[0].selected" ></v-radio></div>
                    <div><v-radio :label="val.answers[1].answer" :value="val.qxid +'|'+ val.answers[1].axid +'|'+ val.answers[1].selected" ></v-radio></div>
                    <div v-if="val.answers.length===3"><v-radio :label="val.answers[2].answer" :value="val.qxid +'|'+ val.answers[2].axid +'|'+ val.answers[2].selected" ></v-radio></div>

                  <!-- </div> -->
                </v-radio-group>                
              </div>
            </div>
          </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div style="display: flex;justify-content: center;">
         <v-btn
            color="black"
            @click="submitSurvey"
            width="70%"
            class="white--text"
          >
            Submit
          </v-btn>
          </div>
        </v-col>
      </v-row>

    </v-container>
  </v-form>
  
</template>

<script>
  import {mapActions, mapState} from 'vuex';
  export default {
    name: 'SurveyComp',
    computed: {
      //...mapGetters(["allTodos", "allTranslist"]), - map to a getter
      ...mapState(["currentLogo", "currentProductName", "currentProductUrl", "currentProductTxt"]), // map directly to a state
      //...mapActions(['']),
    },
    components: {
      
    },
    data: () => ({
      questions: [],
      xid: 0,
      wxid: 0,
      msg: '',
      numQa: 0,
      qa: []
    }),
    methods: {
      ...mapActions(['postSurvey', 'fetchSurvey', 'completeSurvey']), 
      submitSurvey(){
        //console.log('-',this.qa)

        if(this.numQa === this.qa.length){
          this.msg = 'Survey Completed.';

          this.completeSurvey({answers:this.qa, xid: this.xid, wxid: this.wxid}).then((v) =>{
            //console.log('--> survey qa=', v);

            if(v.status === 200){
              //console.log('--> survey 200');
              this.$router.push({ path: '/earn' })
            }else{
              //console.log('--> survey !200');
            }
          });
        }else{
          this.msg = 'Please complete all questions.'
        }

        return false;
      },
      learnMoreClk(){
        // let pl = {
        //   xid: this.xid
        // };
        //console.log(this.currentProductUrl, pl);

        //this.learnMore(pl)
        window.open(this.currentProductUrl, '_blank', 'noreferrer');
      }
    },
    created() {
      // this.updateShowAppBar(true)
      // this.enableMenuVal(false);

      this.xid = this.$route.query.xid;
      this.wxid = this.$route.query.wixid;

      this.fetchSurvey({xid:this.xid}).then((v) =>{
        
        if(v.data.survey && v.data.survey.length > 0){
          this.questions = v.data.survey;
          this.numQa = v.data.survey.length;

          //console.log('--> qs=', this.questions);

          for (let i = 0; i < this.questions.length; i++) {
            let q = this.questions[i];
            for (let j = 0; j < q.answers.length; j++) {
              let a = q.answers[j];
              if(a.selected === true){
                this.qa[i] = q.qxid +'|'+ a.axid +'|'+ a.selected
              }
            }
          }
        }else{
          this.$router.push({ path: '/earn' })
        }
      });
    }
  }
</script>
