<template>
  <play-comp msg="Welcome to Your Video"/>
</template>

<script>
  import PlayComp from '../components/Play'

  export default {
    name: 'PlayView',

    components: {
      PlayComp,
    },
  }
</script>
