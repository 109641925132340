<template>
  <v-form 
  ref="form"
  v-model="valid"
  lazy-validation
  >
  <v-container>
    <v-row class="mt-6">
        <v-col align="center" align-self="end">
          <v-img
            alt="Adreel Logo"
            class="shrink mr-2"
            contain
            src="@/assets/images/Adheader.png"
            transition="scale-transition"
            width="150"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col> Recover Password </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            :centered="centered"
          >
            {{ text }}

            <template v-slot:action="{ attrs }">
              <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="snackbar = false"
              >
                X
              </v-btn>
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="email"
            label="Email"
            :rules="rules.email"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col> 
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="validate"
          >
            Recover Password
          </v-btn>

          <v-btn
            color="success"
            class="mr-4"
            @click="home"
          >
            Back
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
  import {recoverPass} from '../service/adwsvc'
  export default {
    name: 'PassRecoverComp',
    props:['msg'],
    computed: {
    },
    data: () => ({
      valid: true,
      show1: false,
      email: '',
      snackbar: false,
      text: '',
      centered: true,
      timeout: 3000,
      rules: {
        required: value => !!value || 'Required.',
        email: [
          v => !!v || 'Required!',
          v => /.+@.+/.test(v) || 'E-mail must be valid',
        ],
      },
    }),
    methods: {
      async validate () {
        recoverPass(this.email).then( () =>{
            this.snackbar = true;
            this.text = 'A temporary password will be sent to the email.'
            setTimeout( () => {
              this.$router.push({ path: '/' })
            }, 3500);
        } )
        .catch(() => {
          this.snackbar = true;
            this.text = 'Error recovering, please try again or contact Adreel support.'
        });
      },
      async home () {
        this.$router.push({ path: '/' })
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
    },
  }
</script>