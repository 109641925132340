<template>
  <logout-comp/>
</template>

<script>
  import LogoutComp from '../components/Logout'

  export default {
    name: 'LogoutView',

    components: {
      LogoutComp,
    },
  }
</script>
