<template>
  <pass-reset-comp msg="wallet" />
</template>

<script>
  import PassResetComp from '../components/PassReset'

  export default {
    name: 'PassResetView',

    components: {
      PassResetComp,
    },
  }
</script>
