<template>
  <wallet-comp msg="wallet" />
</template>

<script>
  import WalletComp from '../components/Wallet'

  export default {
    name: 'HomeView',

    components: {
      WalletComp,
    },
  }
</script>
