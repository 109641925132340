let protocol = "https://"
//let host = "localhost"
let host = "lapi.myadreel.com/mem"
let port = "443"

export const baseEp = protocol + host

export const acctCredentialsEp = protocol + host + ":" + port + "/ar-dash/acct/credentials"
export const acctEp = protocol + host + ":" + port + "/ar-dash/acct"
export const campaignEp = protocol + host + ":" + port + "/ar-dash/campaign"
export const lookupEp = protocol + host + ":" + port + "/ar-dash/arlu"
export const mediaEp = protocol + host + ":" + port + "/api/media"
export const registerEp = protocol + host + ":" + port + "/ar-dash/register"