<template>
  <v-container>
    <!-- <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.svg')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          <h1>{{ msg }} </h1>
        </h1>
      </v-col>
    </v-row> -->
    <!-- <v-btn
      color="error"
      class="mr-4"
      @click="transfer"
    >
      Transfer Credits
    </v-btn> -->

    <br/><br/>
    
    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel>      
        <v-expansion-panel-header style="background:#4caf50;color:white">
          <table style="width:100%"><tr>
            <td>Transactions</td>
            <td>Wallet: {{ allTranslist.wamt }}</td>
          </tr></table>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    App
                  </th>
                  <th class="text-left">
                    Transaction Date
                  </th>
                  <th class="text-left">
                    Credits
                  </th>
                  <th class="text-left">
                    Item Purchased
                  </th>
                </tr>
              </thead>
              <tbody>
                <!-- <tr
                  v-for="tx in allTranslist.recent"
                  :key="tx.id"
                  @click="transSum(tx.id)"
                > -->
                <tr
                  v-for="tx in allTranslist.trans"
                  :key="tx.id"
                >
                  <td>{{ tx.name }}</td>
                  <td>{{ tx.dt }}</td>
                  <td>{{ tx.credits }}</td>
                  <td>{{ tx.itmName }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <br/><br/>

    <v-expansion-panels>
      <v-expansion-panel
        v-for="(tx,i) in allTranslist.yr"
        :key="i"
      >      
        <v-expansion-panel-header style="background:#4caf50;color:white">
          <table style="width:100%"><tr>
            <!-- <td style="width:30%">{{tx.month}}</td>
            <td style="width:30%"># Transactions {{tx.data.length}}</td>
            <td>Total {{tx.ttl}}</td> -->
            <td>2022 Transactions</td>
          </tr></table>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    App
                  </th>
                  <th class="text-left">
                    Transaction Date
                  </th>
                  <th class="text-left">
                    Credits
                  </th>
                  <th class="text-left">
                    Item Purchased
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="yrtx in tx.data"
                  :key="yrtx.id"
                  @click="transSum(yrtx.id)"
                >
                  <td>{{ yrtx.name }}</td>
                  <td>{{ yrtx.dt }}</td>
                  <td>{{ yrtx.credits }}</td>
                  <td>{{ yrtx.merch }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
  import {mapState, mapActions} from 'vuex';
  export default {
    name: 'WalletComp',
    props:['msg'],
    computed: {
      //...mapGetters(["allTodos", "allTranslist"]), - map to a getter
      ...mapState(["allTodos", "allTranslist"]), // map directly to a state
    },
    data: () => ({
      txs: {
        recent: [],
        yr:[],
      }, 
      ecosystem: [
        
      ],
      importantLinks: [
        
      ],
      whatsNext: [
        
      ],
      panel: [0]
    }),
    methods: {
      ...mapActions(['fetchTranslist', 'selectTargetTrans']), 
      transfer() {
        this.$router.push({ path: '/transfer' })
      },
      transSum(id) {
        this.selectTargetTrans(id);
        this.$router.push({ path: `/transSum/${id}` })
      }
    },
    created() {
        this.fetchTranslist();
    }
  }
</script>
