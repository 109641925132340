<template>
  <v-form 
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-container dense=true>
      <v-row>
        <v-col> Adreel Profile</v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            :centered="centered"
          >
            {{ text }}

            <template v-slot:action="{ attrs }">
              <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="snackbar = false"
              >
                X
              </v-btn>
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
      <v-row>
      <v-col cols="12" md="6">  
      <!-- <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="name"
            label="Name"
            :readonly="editFlag"
            :disabled="editFlag"
            :rules="[rules.required,rules.min4]"
          >
          </v-text-field>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="email"
            :rules="rules.email"
            label="Email"
            :readonly="true"
            :disabled="true"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
            type="date"
              v-model="dob"
              :readonly="true"
              :disabled="true"
              label="Birth Day (14 yr minimum)"
              :rules=[rules.required]
            >
            </v-text-field>
          </v-col>
        </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-select
            :items="items"
            v-model="gender"
            :readonly="true"
            :disabled="true"
            label="Gender"
            :rules=[rules.required]
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="zip"
            label="Zip Code"
            :rules="[rules.required, rules.equal5]"
            :readonly="true"
            :disabled="true"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="phone"
            label="Phone"
            :readonly="true"
            :disabled="true"
            :rules="[rules.required, rules.equal12]"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col> 
          <v-btn
            color="success"
            class="mr-4"
            @click="validateProfile"
          >
            {{editProfileLbl}}
          </v-btn>

          <v-btn
            color="error"
            class="mr-2"
            @click="cancelProfile"
          >
            Cancel
          </v-btn>
        </v-col>
      </v-row> -->
      </v-col>
      <v-col cols="12" md="6">
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="passwordc"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min8]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Enter Current Password"
            hint="At least 8 characters"
            counter
            @click:append="show1 = !show1"
            :readonly="passFlag"
            :disabled="passFlag"
          >
          </v-text-field>
        </v-col>
      </v-row>     
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="password1"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min8]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Enter New Password"
            hint="At least 8 characters"
            counter
            @click:append="show1 = !show1"
            :readonly="passFlag"
            :disabled="passFlag"
          >
          </v-text-field>
        </v-col>
      </v-row>     
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="password2"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min8]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Re-Enter New Password"
            hint="At least 8 characters"
            counter
            @click:append="show1 = !show1"
            :readonly="passFlag"
            :disabled="passFlag"
          >
          </v-text-field>
        </v-col>
      </v-row>
      
      <v-row>
        <v-col> 
          <v-btn
            color="success"
            class="mr-4"
            @click="validateCred"
          >
            {{editCredLbl}}
          </v-btn>

          <v-btn
            color="error"
            class="mr-2"
            @click="cancelCred"
          >
            Cancel
          </v-btn>
        </v-col>
      </v-row>
      </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
  import {retrieveProfile, updateProfile} from '../service/adwsvc'
  import {mapState} from 'vuex';
  export default {
    name: 'ProfileComp',
    data: () => ({
      items: [{text:'Male',value:'M'}, {text:'Female',value:'F'}, {text:'Non-Binary',value:'B'}],
      editFlag: true,
      passFlag: true,
      valid: true,
      show1: false,
      passwordc: 'xxxxxxxx',
      password1: 'xxxxxxxx',
      password2: 'xxxxxxxx',
      email: '',
      name: '',
      initialDob: '',
      gender: '',
      phone: '',
      zip: '',
      emailOrig: '',
      nameOrig: '',
      bdayOrig: '',
      genderOrig: '',
      phoneOrig: '',
      zipOrig: '',
      editProfileLbl: 'Edit',
      editCredLbl: 'Edit',
      snackbar: false,
      text: '',
      centered: true,
      timeout: 2000,
      rules: {
        required: value => !!value || 'Required.',
        min4: v => v && v.length >= 4 || 'Min 4 characters',
        min8: v => v && v.length >= 8 || 'Min 8 characters',
        equal5: v => v && v.length == 5 || 'Total 5 characters',
        equal12: v => v && v.length == 12 || 'Total 12 digits',
        email: [
          v => !!v || 'E-mail is required',
          v => /.+@.+/.test(v) || 'E-mail must be valid',
        ],
      },
    }),
    computed: {
      ...mapState(["jwt"]),
      dob: {
        get() {
          return this.initialDob;
        },
        set(newValue) {
          this.initialDob = newValue;
        },
      },
    },
    methods: {
      maskPhone: function(x) {
        if(!x) return;
        
        let pn = x.replace(/-/g, "");

        if(pn.length > 6){
          pn = pn.slice(0,3) + '-' + pn.slice(3, 6) + '-' + pn.substring(6);
        }else if(pn.length === 6){
          pn = pn.slice(0,3) + '-' + pn.substring(3) + '-';
        }else if(pn.length === 3){
          pn = pn + '-'
        }else if(pn.length === 4 || pn.length === 5){
          pn = pn.slice(0,3) + '-'
        }
        this.phone = pn;
      },
      validateProfile () {
        // console.log(this.editFlag, this.$refs.form.validate())
        if(this.editFlag === true){
          this.editFlag = false;
          this.editProfileLbl = 'Update Profile';
          return;
        }

        if(this.$refs.form.validate()){
          let p = {}
          let dirty = false;
          if(this.nameOrig !== this.name){p.name = this.name; dirty = true;}
          if(this.emailOrig !== this.email){p.email = this.email; dirty = true;}
          if(this.phoneOrig !== this.phone){p.phone = this.phone; dirty = true;}
          if(this.genderOrig !== this.gender){p.gender = this.gender; dirty = true;}
          // console.log('--', this.dob, this.bdayOrig, this.bdayOrig === this.dob)
          if(this.zipOrig !== this.zip){p.zip = this.zip; dirty = true;}
          if(this.bdayOrig !== this.dob){p.dob = this.dob; dirty = true;}
          
          if(dirty){
            updateProfile(p, this.jwt).then( resp => {
              // console.log(resp);
              if(resp.status === 200){
                this.snackbar = true;
                this.text = 'Updated!'
              }
            }).finally( () => {
              this.editProfileLbl = 'Edit';
              this.editFlag = true;
            });
          }else{
            this.text = "Nothing to save.";
            this.snackbar = true;
          }
        }
      },
      validateCred () {
        if(this.passFlag === true){
          this.passFlag = false;
          this.editCredLbl = 'Update Credential';
          this.passwordc = '';
          this.password1 = '';
          this.password2 = '';
          return;
        }

        if(this.$refs.form.validate()){
          if(this.password1 !== this.password2){
            this.snackbar = true;
            this.text = 'Passwords Do Not Match!'
            return;
          }
          let p = {
            passc: this.passwordc,
            pass1: this.password1,
            pass2: this.password2
          };
          updateProfile(p, this.jwt).then( resp => {
            // console.log(resp);
            if(resp.status === 200){
              this.snackbar = true;
              this.text = 'Updated!'

              this.nameOrig = this.name;
              this.emailOrig = this.email;
              this.phoneOrig = this.phone;
              this.genderOrig = this.gender;
              this.zipOrig = this.zip;
              this.bdayOrig = this.initialDob;
            }
          }).finally( () => {
            this.passFlag === true;
            this.editCredLbl = 'Edit';
          });
        }
      },
      cancelCred () {
        this.passwordc = 'xxxxxxxx';
        this.password1 = 'xxxxxxxx';
        this.password2 = 'xxxxxxxx';
        this.passFlag = true;
        this.editCredLbl = 'Edit';
        this.$refs.form.resetValidation()
      },
      cancelProfile () {
        this.name = this.nameOrig;
        this.email = this.emailOrig;
        this.maskPhone(this.phoneOrig);
        this.gender = this.genderOrig;
        this.zip = this.zipOrig;
        this.initialDob = this.bdayOrig;
        this.editFlag = true;
        this.editProfileLbl = 'Edit';
        this.$refs.form.resetValidation()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
    },
    beforeMount(){
      retrieveProfile(this.jwt).then((v) =>{
        // console.log(v);
        if(v.status === 200){
          this.name = v.data.profile.name;
          this.email = v.data.profile.email;
          this.maskPhone(v.data.profile.phoneNum);
          this.gender = v.data.profile.gender;
          this.zip = v.data.profile.zipCode;
          let bday = v.data.profile.birthday
          this.initialDob = bday.slice(0,4)+'-'+bday.slice(4,6)+'-'+bday.slice(6,8);

          this.nameOrig = this.name;
          this.emailOrig = this.email;
          this.phoneOrig = this.phone;
          this.genderOrig = this.gender;
          this.zipOrig = this.zip;
          this.bdayOrig = this.initialDob;
        }
      });
    }
  }
</script>
